.parent1 {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-left: 8px;
  margin-bottom: 10px;
}

.parent2 {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 25px;
  margin-bottom: 10px;
}

.thin {
  height: 80px;
  width: 1px;
  background-color: lightgray;
}
