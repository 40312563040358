.header {
  background-color: rgb(243, 243, 243);
  border-bottom: 1px solid rgb(213, 213, 213);
  /* box-shadow: 2px 2px 10px rgb(111, 111, 111); */
  /* font-family: 'Varela Round'; */
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: black;
  border-radius: 3px;
  /* border-width: 2px; */
  /* border-color: rgb(80, 152, 160); */
  /* font-weight: 100px; */
  width: 150px;
  height: 60px;
  background-color: transparent;
  outline: 0;
  appearance: none;
  border: 0;
  font-size: 20px;
  margin-left: 10px;
  transition: all 0.3s;
  cursor: pointer;
}

.contain:hover + .animatedLine {
  display: block;
}
