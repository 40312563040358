.align-left {
  text-align: left;
  width: 100%;
  float: left;
  margin-left: 30px;
}

.align-right {
  text-align: right;
  /* width: 100%; */
  float: right;
  margin-left: 30px;
}

.father {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.top {
  margin-top: 30px;
  text-align: right;
}

.minis2 {
  border: #245570;
  border-style: solid;
  border-width: 5px;
  margin-right: -30px;
  margin-top: 10px;
}

.div {
  margin-left: 0px;
  padding-left: 4px;
  width: 50vw;
  min-height: 60px;
  max-width: 500px;
  background-color: #49b7a8;
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
  font-size: x-large;
  font-weight: 200;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  color: black; /* aae3d9 */
}

.use-height {
  height: 85.4vh;
}

@media screen and (max-height: 640px) {
  .use-height {
    height: 120vh;
  }
}
