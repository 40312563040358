.collapsible {
  background-color: rgb(243, 243, 243); /* #e68273 */
  cursor: pointer;
  padding: 18px;
  width: 70px;
  height: 60px;
  border: none;
  text-align: left;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.collapsible:hover {
  background-color: #b07276;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: rgb(232, 232, 232);
  width: 200px;
  /* box-shadow: 2px 2px 10px rgb(111, 111, 111); */
}
/* 
@keyframes nav {
    from {height: 0;}
    to {height: 100vh;}
} */
