html,
body {
  height: 100%;
}
/* #245570 */
input {
  height: 50px;
  width: 80vw;
  margin-bottom: 15px;
  margin-left: 10px;
  background-color: lightgray;
  border: 0;
  /* border-width: 2px;
    border-style: solid;
    border-color: #245570; */
  border-radius: 8px;
  padding-left: 10px;
  color: black;
  font-size: medium;
  /* box-shadow: 0px 0px 10px #1f303a; */
  /* box-shadow: 2px 2px 20px rgb(174, 130, 58); */
  /* border-color: rgb(181, 181, 181);
    border-style: solid; */
}

/* 
input:focus {
    box-shadow: 2px 2px 20px #4c6676;
    outline: none;
} */

.button {
  height: 20px;
  width: 45px;
  margin-left: -50px;
  margin-top: -15px;
  padding: 5px 5px 35px 5px;
  /* padding-bottom: 40px; */
  /* padding-top: 10px; */
  border-radius: 10px;
  background-color: #245570;
  color: rgb(255, 255, 255);
}

.button:hover {
  padding-left: 15px;
}

::placeholder {
  color: #5d5d5d;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

/* ::selection {
    background-color: #E68273;
} */

h3 {
  height: 400px;
  font-weight: 100;
  background-color: #376782;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.chatP {
  width: 80%;
  background-color: transparent;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.examples {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 100px;
  transform: translate(-50%, -50%);
}

.questions {
  background-color: #e3e3e3;
  height: fit-content;
  color: black;
  font-size: medium;
  margin-left: 0px;
  padding: 5px 5px 5px 5px;
  border: 1px solid #2f2f2f;
}

.questions:hover {
  padding-left: 20px;
}

footer {
  height: 50px;
  width: 100vw;
  color: #5d5d5d;
  padding: 10px 10px 10px 10px;
  background-color: transparent;
  font-size: small;
  text-align: center;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.footer-logo {
  width: 300px;
}

.chatbox {
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  height: auto;
  flex: 1;
  left: 0;
  right: 0;
  top: 0;
  border-radius: "0px";
  /* margin-left: 15;
    margin-right: 15; */
  padding: 10px 10px 10px 10px;
  font-family: "Verdana", sans-serif;
  overflow: "auto";
}
