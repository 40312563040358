.img {
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mother {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  /* gap: 10px; */
  margin-bottom: 60px;
  /* background-color: #253846;
    width: 500px; */
  align-items: flex-start;
  transform: translateX(10%);
  color: black;
  font-size: large;
}

/* rgb(243, 243, 243) #354f5f */

.thick {
  width: 90%;
  height: 2px;
  margin: 10px auto auto auto;
  background-color: rgb(209, 98, 98);
}

.resource {
  margin: 5px 5px 3px 5px;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: x-large;
  color: black;
}
