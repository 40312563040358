body {
  margin: 0px;
}

.heading {
  color: #a9666b;
  letter-spacing: 0.03em;
  height: 50px;
  background-color: #e5756a;
  font-family: "Fjord One", serif;
  margin-left: 0px;
  margin-top: 0px;
  text-indent: 1em;
  padding-top: 15%;
  padding-right: 10px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); */
}

a {
  background-color: transparent;
  text-decoration: none;
  color: darkgray;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* p {
  margin-left: 0px;
  text-indent: 1em;
  width: 500px;
  background-color: #2c607d;
  padding: 2% 2% 2% 2%;
  border-radius: 5px;
  font-size: medium;
} */

.bottom {
  background-color: transparent;
  box-shadow: 0px -5px 20px 1px rgb(120, 120, 120); /* 0px -16px 30px 1px rgb(0, 0, 0) */
  position: relative;
  z-index: 9999;
}

.title {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 30px;
  margin-left: 10%;
  margin-top: 20px;
}

.h2 {
  text-align: center;
  width: 150px;
  margin-bottom: -11px;
  color: #83cec4;
  font-weight: 200;
  margin-left: 10px;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.p {
  text-align: center;
  background-color: transparent; /* #253846 */
  border-radius: 50px;
  width: 300px;
  margin-bottom: 60px;
  color: black;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.parent {
  display: flex;
  justify-content: center;
}

.begin {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.send:hover {
  margin-left: 20px;
}

.airplane:hover {
  padding-left: 20px;
}
